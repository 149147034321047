import { render, staticRenderFns } from "./StatisticsWdTurnoverMerchant.vue?vue&type=template&id=4ab605da&scoped=true&"
import script from "./StatisticsWdTurnoverMerchant.vue?vue&type=script&lang=js&"
export * from "./StatisticsWdTurnoverMerchant.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsWdTurnoverMerchant.vue?vue&type=style&index=0&id=4ab605da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab605da",
  null
  
)

export default component.exports