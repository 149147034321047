export default [
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyTitle,
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Total deposit',
      sort: 'totalDeposit',
    },
    cell: {
      type: 'slot',
      name: 'totalDeposit',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Deposit fee',
    },
    cell: {
      type: 'slot',
      name: 'depositFee',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Total withdrawal',
      sort: 'totalWithdrawal',
    },
    cell: {
      type: 'slot',
      name: 'totalWithdrawal',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Withdrawal fee',
    },
    cell: {
      type: 'slot',
      name: 'withdrawalFee',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Blockchain fee',
    },
    cell: {
      type: 'slot',
      name: 'blockchainFee',
    },
  },
];
