//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StatisticsWdTurnoverWd from '~/views/StatisticsWdTurnover/StatisticsWdTurnoverWd/StatisticsWdTurnoverWd.vue';
import StatisticsWdTurnoverCodes from '~/views/StatisticsWdTurnover/StatisticsWdTurnoverCodes/StatisticsWdTurnoverCodes.vue';
import StatisticsWdTurnoverMerchant from '~/views/StatisticsWdTurnover/StatisticsWdTurnoverMerchant/StatisticsWdTurnoverMerchant.vue';

export default {
  components: {
    StatisticsWdTurnoverWd,
    StatisticsWdTurnoverCodes,
    StatisticsWdTurnoverMerchant,
  },
};
