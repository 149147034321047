var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('div',{staticClass:"pt-3"},[_c('UiSelect',{attrs:{"items":_vm.currencies,"item-text":function (e) { return e.title; },"label":"Currency","placeholder":_vm.$t('All currencies')},model:{value:(_vm.chartCurrency),callback:function ($$v) {_vm.chartCurrency=$$v},expression:"chartCurrency"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-grow-0 section__col"},[_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"431px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"totalDeposit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[_c('div',[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.amountDeposit, row.currencyId))+" "+_vm._s(row.currencyTitle)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.cumulativeAmountDeposit, _vm.additionalData.cumulativeCurrencyId))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])])]}},{key:"depositFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[_c('div',[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.feeDeposit, row.currencyId))+" "+_vm._s(row.currencyTitle)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.cumulativeFeeDeposit, _vm.additionalData.cumulativeCurrencyId))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])])]}},{key:"totalWithdrawal",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[_c('div',[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.amountWithdrawal, row.currencyId))+" "+_vm._s(row.currencyTitle)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.cumulativeAmountWithdrawal, _vm.additionalData.cumulativeCurrencyId))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])])]}},{key:"withdrawalFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[_c('div',[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.feeWithdrawal, row.currencyId))+" "+_vm._s(row.currencyTitle)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.cumulativeFeeWithdrawal, _vm.additionalData.cumulativeCurrencyId))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])])]}},{key:"returnFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[_c('div',[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.feeReturn, row.currencyId))+" "+_vm._s(row.currencyTitle)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.cumulativeFeeReturn, _vm.additionalData.cumulativeCurrencyId))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])])]}}])})],1)]),_c('v-col',{staticClass:"flex-grow-0 section__col"},[_c('Chart')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }