//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        dateFrom: '',
        dateTo: '',
        cumulativeCurrencyId: 0,
        period: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('statisticsWdTurnoverWd', ['filterLists']),
    ...mapGetters('currencies', ['getCurrencyByTitle']),
    ...mapState('users', ['userTagsList']),

    priceEquivalentList() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getCurrencyByTitle(e));
      return list;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
    const { id } = this.getCurrencyByTitle(PRICE_EQUIVALENT_CURRENCY_LIST[0]);

    const { PERIOD_LIST } = constant.statistics;
    const period = PERIOD_LIST[0].id;

    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7); // set dateFrom as one week ago
    const dateFromString = moment(dateFrom).format('YYYY-MM-DD');

    const dateTo = new Date();
    const dateToString = moment(dateTo).format('YYYY-MM-DD');

    this.filters = {
      ...this.filters,
      dateFrom: dateFromString,
      dateTo: dateToString,
      cumulativeCurrencyId: id,
      period,
    };
  },

  methods: {
    ...mapActions('statisticsWdTurnoverWd', ['setFilters']),
  },
};
