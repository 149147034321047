//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './StatisticsWdTurnoverCodesFilters.vue';
import Chart from './StatisticsWdTurnoverCodesChart.vue';
import constant from '~/const';

import tableConfig from './tableConfig';

export default {
  components: { Filters, Chart },

  data() {
    return {
      tableConfig,
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('currencies', ['currencies']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    ...mapState('statisticsWdTurnoverCodes', [
      'page',
      'pages',
      'data',
      'chart',
      'additionalData',
      'sort',
      'chartFilters',
    ]),

    chartCurrency: {
      get() {
        const { currencyIdList } = this.chartFilters;
        return currencyIdList;
      },
      async set(data) {
        const { chartFilters } = this;
        this.setChartFiltersAction({
          ...chartFilters,
          currencyIdList: data,
        });
        this.setGeneralProgress(true);
        await this.loadChartAction();
        this.setGeneralProgress(false);
      },
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('statisticsWdTurnoverCodes', {
      loadDataAction: 'loadData',
      loadChartAction: 'loadChart',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      setChartFiltersAction: 'setChartFilters',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await Promise.allSettled([
        this.loadDataAction(data),
        this.loadChartAction(),
      ]);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },
  },
};
